import {
  Button,
  Container,
  ContentLayout,
  Form,
  Header,
  SpaceBetween,
  TextContent,
} from '@amzn/awsui-components-react';
import React, { useEffect } from 'react';
import suspendedAccountMessages from '../../i18n/suspendedAccount.messages';
import { useIntl } from 'react-intl';
import { PostAuthComponentParams } from '../../types/auth';
import HelpLink from '../HelpLink';
import { emitPostAuthRumEvent } from '../../services/emitPostAuthRumEvent';
import { PostAuthRumEvent } from '../../constants/metrics';

const SuspendedAccount = ({
  config,
  onContinue,
  onCancel,
  user,
}: PostAuthComponentParams) => {
  const { formatMessage } = useIntl();
  useEffect(() => {
    if (
      user?.suspended === 'true' &&
      config.enableSuspendedAccountFlow === 'true'
    ) {
      emitPostAuthRumEvent(user, PostAuthRumEvent.USER_SUSPENDED);
    }
  }, [config.enableSuspendedAccountFlow, user]);
  if (
    config.enableSuspendedAccountFlow !== 'true' ||
    user?.suspended !== 'true'
  ) {
    onContinue();
    return <></>;
  }

  return (
    <ContentLayout>
      <SpaceBetween direction="vertical" size="xxs">
        <Container
          header={
            <Header variant={'h2'}>
              {formatMessage(suspendedAccountMessages.header)}
            </Header>
          }
        >
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    onCancel();
                  }}
                  data-testid={'return-to-signin'}
                  variant={'primary'}
                >
                  {formatMessage(
                    suspendedAccountMessages.returnToSignInPageButton
                  )}
                </Button>
              </SpaceBetween>
            }
          >
            <SpaceBetween direction="vertical" size="xs">
              <TextContent>
                {formatMessage(suspendedAccountMessages.messageBody)}
              </TextContent>
            </SpaceBetween>
          </Form>
        </Container>
        <HelpLink />
      </SpaceBetween>
    </ContentLayout>
  );
};
export default SuspendedAccount;
