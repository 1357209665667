import { Locales } from '@amzn/xb-hooks';

export const xbosConfig = {
  default: {
    cognito: {
      IdentityPoolId: 'us-east-1:2bd1b78b-b38a-485c-8ea2-b2664451ab94',
      region: 'us-east-1',
    },
    evidently: {
      region: 'us-east-1',
      endpoint: 'https://evidently.us-east-1.amazonaws.com',
    },
    sts: {
      region: 'us-east-1',
      unauthRole:
        'arn:aws:iam::843378256785:role/AT2Experiments-Gamma-Comm-GammaAT2ExperimentsCogni-ES4QVUTOGD4G',
      roleSessionName: 'evidently',
    },
    apolloAppUri: 'http://localhost:3000/graphql',
    supportedLocales: Locales.asList(),
    apolloClientLegacy: true,
  },
  beta: {
    cognito: {
      IdentityPoolId: 'us-east-1:2bd1b78b-b38a-485c-8ea2-b2664451ab94',
      region: 'us-east-1',
    },
    evidently: {
      region: 'us-east-1',
      endpoint: 'https://evidently.us-east-1.amazonaws.com',
    },
    apolloAppUri: 'https://xbosified-app.training.aws.dev/graphql',
    supportedLocales: Locales.asList(),
    apolloClientLegacy: true,
  },
  gamma: {
    apolloAppUri: 'https://skillbuilder.training.aws.dev/graphql',
    supportedLocales: Locales.asList(),
    apolloClientLegacy: true,
  },
};

export const localXPSConfig = [
  {
    name: 'example',
    componentEndpoint: 'http://localhost:3005/',
  },
];
