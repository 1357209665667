import { Box, ButtonDropdown, Icon } from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';

import globeIcon from '../assets/globe.svg';
import globalMessages from '../i18n/global.messages';
import { useLocaleContext } from '../contexts/LocaleContextProvider';
import ScreenReaderHidden from './ScreenReaderHidden';
import { Locale, HC_LOCALES, TextDirection } from '../constants/locales';
import metricsService from '../services/metrics';
import { getSafeLocale } from '../utils/locale';

/**
 * Filter to only include LTR locales.
 * RTL locales are not yet supported. https://sim.amazon.com/issues/Vibe-485
 */
const isLtrLocale = (locale: Locale) => locale.dir === TextDirection.LTR;

const idSeparator = ':';

const LocaleMenu = () => {
  const { formatMessage } = useIntl();
  const { locale, setLocale } = useLocaleContext();

  const safeLocale = getSafeLocale(locale, 'LocaleMenu');
  if (locale !== safeLocale) {
    setLocale(safeLocale);
  }

  return (
    <ButtonDropdown
      items={[
        ...Object.keys(HC_LOCALES)
          .map((key) => HC_LOCALES[key])
          .filter(isLtrLocale)
          .map((l) => ({
            text: l.label,
            id: `header-localeItem${idSeparator}${l.id}`,
            disabled: safeLocale === l.id,
            lang: l.lang,
          })),
      ]}
      onItemClick={(event) => {
        const selectedLocale = event.detail.id.split(idSeparator)[1];
        setLocale(selectedLocale);
        const publisher = metricsService.getPublisher('LocaleMenu');
        publisher.publishCounterMonitor('ClickLocale', 1);
        publisher.publishStringTruncate('SelectedLocale', selectedLocale);
      }}
      data-testid="header-localeMenu"
    >
      <Box margin={{ right: 'xs' }} display="inline">
        <Icon url={globeIcon} alt="Globe Icon" />
      </Box>
      <ScreenReaderHidden>
        {formatMessage(globalMessages.selectPreferredLocale)}
      </ScreenReaderHidden>
      <ScreenReaderHidden>
        {formatMessage(globalMessages.currentLocaleSelection)}
      </ScreenReaderHidden>
      {HC_LOCALES[safeLocale].label}
    </ButtonDropdown>
  );
};

export default LocaleMenu;
