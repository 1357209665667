import { DEFAULT_LOCALE, HC_LOCALES } from '../constants/locales';
import { rumService } from '../services/rum';

export const getSafeLocale = (locale: string, sourceComponent: string) => {
  let safeLocale = locale;
  if (!HC_LOCALES.hasOwnProperty(locale)) {
    const urlParams = new URLSearchParams(window.location.search);
    const clientId = urlParams.get('client_id') || 'NA';
    rumService.recordEvent('UnsupportedLocale', {
      unsupportedLocale: locale,
      sourceComponent: sourceComponent,
      clientId: clientId,
    });
    safeLocale = DEFAULT_LOCALE;
  }

  return safeLocale;
};
