import { TextContent } from '@amzn/awsui-components-react';
import { Component } from 'react';
import { injectIntl, IntlShape } from 'react-intl';

import errorMessages from '../i18n/error.messages';
import ErrorLogger from './ErrorLogger';

class ErrorBoundary extends Component<{
  FallbackComponent?: React.ReactType;
  intl: IntlShape;
}> {
  public state = {
    hasError: false,
    error: null,
  };

  static getDerivedStateFromError(error: Error | null) {
    return { hasError: true, error };
  }

  render() {
    if (!this.state.hasError) {
      return <ErrorLogger>{this.props.children}</ErrorLogger>;
    }

    const FallbackComponent = this.props.FallbackComponent;
    return FallbackComponent ? (
      <FallbackComponent
        details={this.state.error && (this.state.error as any).toString()}
      />
    ) : (
      <TextContent>
        {this.props.intl.formatMessage(
          errorMessages.somethingWrongPleaseReload
        )}
      </TextContent>
    );
  }
}

export default injectIntl(ErrorBoundary);
