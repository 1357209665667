import { DEFAULT_LOCALE, HC_LOCALES } from '../constants/locales';
import { LOCALE_SELECTION } from '../constants/storage';

const isLocaleSupported = (locale: string) => Boolean(HC_LOCALES[locale]);

/**
 * Tries to get the browsers configured language in case it is in the list of
 * supported locales.
 * Note: Given a browser language like 'en-US' both 'en-US' and 'en' will be
 * checked.
 */
export function getSupportedBrowserLocaleMaybe() {
  let browserLocale: string | undefined;

  navigator.languages.find((locale) => {
    if (isLocaleSupported(locale)) {
      browserLocale = locale;
    }
    const shortLocale = locale.split('-')[0];
    if (isLocaleSupported(shortLocale)) {
      browserLocale = shortLocale;
    }
    return Boolean(browserLocale);
  });

  return browserLocale;
}

/**
 * Get the initial locale string by looking to see if the user has a valid one already set,
 * trying falling back to the browsers locale if it's not set, and defaulting to English
 * if we can't determine a better selection.
 *
 * @param [defaultLocale=DEFAULT_LOCALE] - the default locale to fallback to
 * if one is not set for the user or browser.
 */
export function getInitialLocaleSelection(
  defaultLocale: string = DEFAULT_LOCALE
): string {
  // Try to get the users locale selection from localStorage.
  const userSelection = localStorage.getItem(LOCALE_SELECTION);
  if (userSelection && isLocaleSupported(userSelection)) {
    return userSelection;
  }

  // Try to get locale selection from browser language API.
  const browserLocale = getSupportedBrowserLocaleMaybe();
  if (browserLocale) return browserLocale;

  return defaultLocale;
}
