export const IMPRESSIONS = 'Impressions';

export const UPDATE_EULA = 'UpdateEula';

export const CHECK_NEEDS_VERIFICATION = 'CheckNeedsVerification';

export const GANDALF_SESSION = 'GandalfSession';

export const UPDATE_USER_MERGE_CONSENTED = 'UpdateUserMergeConsented';

export const CHECK_USER_MERGE_CONSENT_REQUIRED =
  'CheckUserMergeConsentRequired';

export const UPDATE_CONSENT = 'UpdateConsent';

export enum PostAuthRumEvent {
  // "Homecoming" related flows
  CONSENT_SHOWN = 'consent_shown',
  CONSENT_REJECTED = 'consent_rejected',
  CONSENT_ACCEPTED = 'consent_accepted',
  CONSENT_AUTO_ACCEPT = 'consent_auto_accept',
  REQUIRED_EMAIL_VERIFICATION_START = 'required_email_verification_start',
  USER_SUSPENDED = 'user_suspended',
  USER_DEACTIVATED = 'user_deactivated',
  SIGN_IN_SWITCH_NEEDED = 'sign_in_switch_needed',
  PROFILE_COMPLETE = 'profile_complete',
  PROFILE_SHOWN = 'profile_shown',

  // Other flows
  SKIP_POSTAUTH_FLOWS_FOR_ELTI = 'skip_post-auth_flows_for_elti',
}
