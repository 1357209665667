import { useRef, useEffect } from 'react';
import CardContainer from '../components/CardContainer';
import ContainerLayout from '../components/ContainerLayout';
import Footer from '../components/Footer';
import Main from '../components/Main';
import TopHeader from '../components/TopHeader';
import metricsService from '../services/metrics';
import { IdPs } from '../constants/providers';
import { LAST_USED_PROVIDER_KEY } from '../constants/storage';
import { localStorageAdapter } from '../services/storage';
import { AppConfig } from '../types/app';
import { useIntl } from 'react-intl';
import LogoutInstructions from '../components/LogoutInstructions';
import { renderProviderName } from '../utils/renderProviderName';

export const Logout = ({ config }: { config: AppConfig }) => {
  const { formatMessage } = useIntl();

  const { idp } = JSON.parse(
    localStorageAdapter.getItem(LAST_USED_PROVIDER_KEY) || '{}'
  );
  let providerDisplayName = renderProviderName(idp?.idp, formatMessage);

  const metricsPublisher = useRef(metricsService.getPublisher('Logout'));

  useEffect(() => {
    metricsPublisher.current.publishCounterMonitor('Impressions', 1);
  }, []);
  useEffect(() => {
    if (!providerDisplayName) {
      metricsPublisher.current.publishCounterMonitor(
        'ProviderDisplayNameNotfound',
        1
      );
    }
  }, [providerDisplayName]);
  const enableCookieComponent = Boolean(
    config?.enableCookieComponent === 'true'
  );
  return (
    <>
      <TopHeader config={config} />
      <Main config={config}>
        <ContainerLayout>
          {
            <CardContainer>
              <LogoutInstructions config={config} idp={idp.idp as IdPs} />
            </CardContainer>
          }
        </ContainerLayout>
      </Main>
      <Footer enableCookieComponent={enableCookieComponent} />
    </>
  );
};
