import { defineMessages } from 'react-intl';

const logoutMessages = defineMessages({
  signedOutLxp: {
    id: 'logout.signedOutLxp',
    defaultMessage: `You have signed out of {lxp}`,
    description: 'Text that you are signed out of LXP',
  },
  signedOutSkillBuilder: {
    id: 'logout.signedOutLxp',
    defaultMessage: `You have signed out of <skillbuilderurl>{lxp}</skillbuilderurl>`,
    description: 'Text that you are signed out of LXP',
  },
  signedOutIdPAlso: {
    id: 'logout.signedOutIdPAlso',
    defaultMessage: `You signed into {lxp} using {idp}, and may still be signed into {idp} on this device. To sign out of {idp}`,
    description: 'Text that you need to sign out of Idp',
  },
  signedOutIdPAlsoSkillBuilder: {
    id: 'logout.signedOutIdPAlso',
    defaultMessage: `You signed into <skillbuilderurl>{lxp}</skillbuilderurl> using {idp}, and may still be signed into {idp} on this device. To sign out of {idp}`,
    description: 'Text that you need to sign out of Idp',
  },
  bidSignOutInstructionsOne: {
    id: 'logout.bidSignOutInstructionsOne',
    defaultMessage: `Visit <link>https://profile.aws.amazon.com/</link>`,
    description: 'BID Sign Out Instructions One',
  },
  bidSignOutInstructionsTwo: {
    id: 'logout.bidSignOutInstructionsTwo',
    defaultMessage: `In the top right of your AWS Builder ID profile page, choose Sign out.`,
    description: 'BID Sign Out Instructions Two',
  },
  forMoreInformation: {
    id: 'logout.forMoreInformation.text',
    defaultMessage: `For more information, see <link>these instructions</link>.`,
    description: 'For more information link',
  },
  lwaSignOutInstructionsOne: {
    id: 'logout.lwaSignOutInstructionsOne',
    defaultMessage: `Navigate to the Amazon website`,
    description: 'LWA Sign Out Instructions One',
  },
  lwaSignOutInstructionsTwo: {
    id: 'logout.lwaSignOutInstructionsTwo',
    defaultMessage: `Select Accounts & Lists in the top right`,
    description: 'Lwa Sign Out Instructions Two',
  },
  lwaSignOutInstructionsThree: {
    id: 'logout.lwaSignOutInstructionsThree',
    defaultMessage: `In the drop-down menu that appears, select Sign Out`,
    description: 'Lwa Sign Out Instructions Three',
  },
  apnSignOutInstructions: {
    id: 'logout.apnSignOutInstructions',
    defaultMessage: `To completely sign out, please visit <link>AWS Partner Central</link> and click logout.`,
    description: 'APN Sign Out Instructions',
  },
  essoSignOutInstructions: {
    id: 'logout.essoSignOutInstructions',
    defaultMessage: `To completely sign out, logout from your Enterprise SSO.`,
    description: 'ESSO Sign Out Instructions',
  },
  midwaySignOutInstructionsOne: {
    id: 'logout.midwaySignOutInstructions',
    defaultMessage: `You may be logged in with Amazon Federate.`,
    description: 'Midway Sign Out Instructions One',
  },
  midwaySignOutInstructionsTwo: {
    id: 'logout.midwaySignOutInstructionsTwo',
    defaultMessage: `To completely sign out, please follow <link>these instructions.</link>`,
    description: 'Midway Sign Out Instructions One',
  },
  bidSignOutInstructionsSkillBuilderUpdated: {
    id: 'logout.bidSignOutInstructionsSkillBuilderUpdated',
    defaultMessage: `You are now signed out of <skillbuilderurl>{lxp}</skillbuilderurl>, however you may still be signed into AWS Builder ID on this device. To sign out of AWS Builder ID completely, visit <link>https://profile.aws.amazon.com</link> and sign out. {br} {br} For more information, see <instructions>these instructions.</instructions>`,
  },
  amazonFederateSignOutInstructionsSkillBuilderUpdated: {
    id: 'logout.amazonFederateSignOutInstructionsSkillBuilderUpdated',
    defaultMessage: `You are now signed out of <skillbuilderurl>{lxp}</skillbuilderurl>, however you may still be signed into Amazon employee single sign-on on this device. To sign out of Amazon employee single sign-on completely, follow <link>these instructions.</link>`,
  },
  awsPartnerNetworkSignOutInstructionsSkillBuilderUpdated: {
    id: 'logout.awsPartnerNetworkSignOutInstructionsSkillBuilderUpdated',
    defaultMessage: `You are now signed out of <skillbuilderurl>{lxp}</skillbuilderurl>, however you may still be signed into AWS Partner Network on this device. To sign out of AWS Partner Network completely, visit <link>AWS Partner Central</link> and logout.`,
  },
  organizationESSOSignOutInstructionsSkillBuilderUpdated: {
    id: 'logout.organizationESSOSignOutInstructionsSkillBuilderUpdated',
    defaultMessage: `You are now signed out of <skillbuilderurl>{lxp}</skillbuilderurl>, however you may still be signed into Organization SSO on this device. `,
  },
  emailOtpSignOutInstructionsSkillBuilderUpdated: {
    id: 'logout.emailOtpSignOutInstructionsSkillBuilderUpdated',
    defaultMessage: `You are now signed out of <skillbuilderurl>{lxp}</skillbuilderurl>, however you may still be signed into One-time passcode on this device. `,
  },
  bidSignOutInstructionsUpdated: {
    id: 'logout.bidSignOutInstructionsUpdated',
    defaultMessage: `You are now signed out of {lxp}, however you may still be signed into AWS Builder ID on this device. To sign out of AWS Builder ID completely, visit <link>https://profile.aws.amazon.com</link> and sign out. {br} {br} For more information, see <instructions>these instructions.</instructions>`,
  },
  amazonFederateSignOutInstructionsUpdated: {
    id: 'logout.amazonFederateSignOutInstructionsUpdated',
    defaultMessage: `You are now signed out of {lxp}, however you may still be signed into Amazon employee single sign-on on this device. To sign out of Amazon employee single sign-on completely, follow <link>these instructions.</link>`,
  },
  awsPartnerNetworkSignOutInstructionsUpdated: {
    id: 'logout.awsPartnerNetworkSignOutInstructionsUpdated',
    defaultMessage: `You are now signed out of {lxp}, however you may still be signed into AWS Partner Network on this device. To sign out of AWS Partner Network completely, visit <link>AWS Partner Central</link> and logout.`,
  },
  organizationESSOSignOutInstructionsUpdated: {
    id: 'logout.organizationESSOSignOutInstructionsUpdated',
    defaultMessage: `You are now signed out of {lxp}, however you may still be signed into Organization SSO on this device. `,
  },
  emailOtpSignOutInstructionsUpdated: {
    id: 'logout.emailOtpSignOutInstructionsUpdated',
    defaultMessage: `You are now signed out of {lxp}, however you may still be signed into One-time passcode on this device. `,
  },
  fallbackLxpName: {
    id: 'logout.fallbackLxpName',
    defaultMessage: 'LXP',
    description: 'Fallback LXP name',
  },
});

export default logoutMessages;
