import { Component, ErrorInfo } from 'react';
import { IMPRESSIONS } from '../constants/metrics';

import metricsService from '../services/metrics';
import { rumService } from '../services/rum';
import logger from '../utils/logger';

class ErrorLogger extends Component {
  public state = {
    hasError: false,
    error: null,
  };

  static getDerivedStateFromError(error: Error | null) {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const errorType = 'RenderError';
    const urlParams = new URLSearchParams(window.location.search);
    const clientId = urlParams.get('client_id');

    const metricsPublisher = metricsService.getPublisher(errorType);
    metricsPublisher.publishCounterMonitor(IMPRESSIONS, 1);
    metricsPublisher.publishStringTruncate('ClientId', clientId || 'NA');
    metricsPublisher.publishStringTruncate('Error', error.message || 'NA');

    rumService.recordError(error);
    logger.error(errorType, error, errorInfo);
    throw error;
  }

  render() {
    return this.state.hasError ? <></> : this.props.children;
  }
}

export default ErrorLogger;
