/* eslint-disable no-console */
import { useEffect } from 'react';
import { LiveComponent } from '@amzn/aws-tc-xb-xps';
import { PostAuthComponentParams } from '../../types/auth';
import { AppConfig } from '../../types/app';
import { useApolloClient } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useIntl } from 'react-intl';
import { emitPostAuthRumEvent } from '../../services/emitPostAuthRumEvent';
import { PostAuthRumEvent } from '../../constants/metrics';

export const getAuthLink = (jwtToken: string) => {
  return setContext((_, { headers }) => {
    console.log('Setting auth header');
    console.log(headers);
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${jwtToken}`,
      },
    };
  });
};

function ProfileAndPreferences({
  config,
  onContinue,
  user,
}: PostAuthComponentParams) {
  const apolloClient = useApolloClient();
  // get current language or locale from useIntl hook
  const { locale } = useIntl();

  useEffect(() => {
    const listener = () => {
      emitPostAuthRumEvent(user, PostAuthRumEvent.PROFILE_COMPLETE);
      onContinue();
    };
    document.addEventListener('edit_profile_all_sections_success', listener);
    return () => {
      document.removeEventListener(
        'edit_profile_all_sections_success',
        listener
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (
      config.enableProfilePreferences !== 'true' ||
      user.profile_completion_required !== 'true' ||
      !shouldRenderProfileAndPreferencesForClient(config)
    ) {
      onContinue();
      return;
    }
    emitPostAuthRumEvent(user, PostAuthRumEvent.PROFILE_SHOWN);
  }, [config, onContinue, user, user.profile_completion_required]);

  if (config.enableProfilePreferences === 'true') {
    console.debug('Setting link');
    console.debug(apolloClient);
    console.debug(apolloClient.link);
    apolloClient.setLink(getAuthLink(user.jwtToken!).concat(apolloClient.link));
    console.debug('Link set');
    console.debug(apolloClient.link);
  }

  console.log('current locale is,', locale);
  return (
    <LiveComponent
      experienceProviderName="userorg"
      componentName="UserProfileEditAll"
      payload={JSON.stringify({
        token: user?.jwtToken,
        language: locale,
      })}
    />
  );
}

const shouldRenderProfileAndPreferencesForClient = (
  config: AppConfig
): boolean => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const client = urlSearchParams.get('client_id');

  if (
    config.userProfileRequiredForClients === undefined ||
    config.userProfileRequiredForClients.length === 0
  )
    return false;

  return config.userProfileRequiredForClients.includes(client!);
};

export default ProfileAndPreferences;
