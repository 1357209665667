import { Identity } from '../types/auth';
import { GANDALF_SESSION } from './metrics';

/**
 * The strings are mapped to the existing Identity Providers in Cognito.
 */
export enum IdPs {
  OTP = 'OTP',
  AWSBuilderID = 'AWSBuilderID',
  AWSSignIn = 'AWSSignIn',
  LoginWithAmazon = 'LoginWithAmazon',
  AmazonFederate = 'AmazonFederate',
  AmazonSystemFederate = 'AmazonSystemFederate',
  ADFS = 'ADFS',
  JPMC = 'JPMC',
  ScaleTestIdp = 'ScaleTestIdp',
  AWSPartnerNetwork = 'AWSPartnerNetwork',
  Accenture = 'Accenture',
  AccentureUAT = 'AccentureUAT',
  BestBuy = 'BestBuy',
  Westpac = 'Westpac',
  Sophos = 'Sophos',
  Jefferies = 'Jefferies',
  Curtin = 'Curtin',
  testProvider = 'testProvider',
  Shell = 'Shell',
  Principal = 'Principal',
  GandalfSession = 'GandalfSession',
}

/**
 * IDPs that are NOT enterprise providers, as enterprise providers will now be
 * handled through Gandalf Identity API.
 */
export const standardProvidersMap: Record<string, string> = {
  [IdPs.OTP]: IdPs.OTP,
  [IdPs.AWSBuilderID]: IdPs.AWSBuilderID,
  [IdPs.AWSSignIn]: IdPs.AWSSignIn,
  [IdPs.LoginWithAmazon]: IdPs.LoginWithAmazon,
  [IdPs.AmazonFederate]: IdPs.AmazonFederate,
  [IdPs.AmazonSystemFederate]: IdPs.AmazonSystemFederate,
  [IdPs.GandalfSession]: IdPs.GandalfSession,
  [IdPs.ADFS]: IdPs.ADFS,
  [IdPs.ScaleTestIdp]: IdPs.ScaleTestIdp,
  [IdPs.AWSPartnerNetwork]: IdPs.AWSPartnerNetwork,
  [IdPs.testProvider]: IdPs.testProvider,
};

export const hiddenProvidersMap: Record<string, string> = {
  [IdPs.AmazonSystemFederate]: IdPs.AmazonSystemFederate,
  [IdPs.GandalfSession]: IdPs.GandalfSession,
};

/**
 * IDPs that are considered enterprise providers. These providers will not be listed in the
 * UI and are only available through the preferred IDP flow or through enterprise email lookup.
 */
export const enterpriseProvidersMap: Record<string, string> = {
  [IdPs.JPMC]: IdPs.JPMC,
  [IdPs.Accenture]: IdPs.Accenture,
  [IdPs.AccentureUAT]: IdPs.AccentureUAT,
  [IdPs.BestBuy]: IdPs.BestBuy,
  [IdPs.Westpac]: IdPs.Westpac,
  [IdPs.Sophos]: IdPs.Sophos,
  [IdPs.Shell]: IdPs.Shell,
  [IdPs.Jefferies]: IdPs.Jefferies,
  [IdPs.Curtin]: IdPs.Curtin,
  [IdPs.Principal]: IdPs.Principal,
};

export const enterpriseShouldbeVisible = 'EnterpriseShouldbeVisible';

/**
 * The prio list decides IdP order in the UI.
 */
const idpUiPrio: { [key: string]: number } = {
  [IdPs.OTP]: 1,
  [IdPs.AWSSignIn]: 2,
};

export const getIdpUiPrio = (idp: string): number => {
  return idpUiPrio[idp] || 9000;
};

export const isStandardProvider = (idp: string): boolean => {
  return Boolean(standardProvidersMap[idp]);
};

export const isEltiProvider = (idpType?: string): boolean => {
  return idpType === IDP_ELTI;
};

export const hasGandalfSessionIdentity = (identities?: [Identity]): boolean => {
  // This logic is based off how GandalfLambda detects SessionIdp
  // See https://code.amazon.com/packages/AWSLabsGandalfLambda/blobs/9db552055e5397e625c45e5cef5f9fa82241f57b/--/handler/AWSLabsGandalfLambda/claim_generators.go#L1053-L1057
  return (
    identities !== undefined &&
    identities.length > 0 &&
    identities[0].providerName === GANDALF_SESSION
  );
};

/**
 * URL params that are required by Cognito Hosted UI when signin in.
 * https://docs.aws.amazon.com/cognito/latest/developerguide/cognito-user-pools-app-integration.html
 */
export const requiredUrlParams = ['redirect_uri', 'response_type', 'client_id'];

/**
 * URL params that are required to render the logout messaging component.
 */
export const requiredLogoutUrlParams = ['client_id'];

/**
 * The default IDP name for Cognito Hosted UI. Specifying this, or nothing, as
 * `identity_provider` in the URL will show all login methods.
 */
export const COGNITO_IDP = 'cognito';

export type GetEmailDomainIdpOutput = {
  idp: string;
  displayName?: string;
};

/**
 * Const for IDP type ELTI
 */
export const IDP_ELTI = 'elti';

/**
 * Represent the provider type ESSO.
 */
export const IDP_ESSO = 'esso';
