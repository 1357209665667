import { Container, ContentLayout, Header } from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';
import identityConsentMessages from '../i18n/identityConsent.messages';
import React from 'react';
import { GandalfUser } from '../types/auth';

const ConsentContainer = ({
  children,
  user,
}: {
  children: React.ReactNode;
  user: GandalfUser;
}) => {
  const { formatMessage } = useIntl();
  return (
    <ContentLayout
      header={
        <Header variant="h1" data-testid={'join-org-header'}>
          {formatMessage(identityConsentMessages.header, {
            orgName: user.invitation?.organization_name,
          })}
        </Header>
      }
    >
      <Container>{children}</Container>
    </ContentLayout>
  );
};

export default ConsentContainer;
