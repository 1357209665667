import * as KatalMetrics from '@amzn/katal-metrics';
import KatalMetricsDriverSushi from '@amzn/katal-metrics-driver-sushi';
import KatalMetricsDriverArrayCollector from '@amzn/katal-metrics/lib/driver/KatalMetricsDriverArrayCollector';
import KatalMetricsDriverConsoleLogJson from '@amzn/katal-metrics/lib/driver/KatalMetricsDriverConsoleLogJson';

import logger from '../utils/logger';
import { isDevelopment } from '../utils/env';
import { AppConfig } from '../types/app';
import { IMPRESSIONS } from '../constants/metrics';

const SITE_NAME = 'AT2SignIn';
const SERVICE_NAME = 'WebApp';

let cachedConfig: AppConfig;
let cachedPublisher: KatalMetrics.Publisher;

const createMetricsDriver = (): KatalMetrics.MetricsDriver => {
  // If config is not initialized use a no-op driver
  // Check this first to make it obvious during development if you forget to call initMetrics()
  if (!cachedConfig?.metricsDomain || !cachedConfig?.metricsRealm) {
    logger.debug('Use KatalMetricsDriverArrayCollector');
    const metricsDriver = new KatalMetricsDriverArrayCollector();
    //  Attach to global window object so tests can see it
    (window as any).metricsDriver = metricsDriver;
    return metricsDriver;
  }

  // Publish metrics to logger for development
  if (isDevelopment) {
    logger.debug('Use KatalMetricsDriverConsoleLogJson');
    return new KatalMetricsDriverConsoleLogJson(logger);
  }

  // For non-development when config has been initialized publish metrics to sushi
  logger.debug('Use KatalMetricsDriverSushi');
  return new KatalMetricsDriverSushi.Builder()
    .withDomainRealm(cachedConfig.metricsDomain, cachedConfig.metricsRealm)
    .withErrorHandler(metricsErrorHandler)
    .build();
};

const metricsErrorHandler = isDevelopment ? logger.error : () => {};

const createInitialMetricsContext = (): KatalMetrics.Context => {
  return new KatalMetrics.Context.Builder()
    .withSite(SITE_NAME)
    .withServiceName(SERVICE_NAME)
    .build();
};

const createPublisher = (): KatalMetrics.Publisher => {
  return new KatalMetrics.Publisher(
    createMetricsDriver(),
    metricsErrorHandler,
    createInitialMetricsContext()
  );
};

/**
 * Capture errors who are bubbling to `window`. The code will
 * Note, this will also capture errors which are caught by error boundaries.
 */
const errorHandler = (event: ErrorEvent) => {
  // Filters out a benign error that comes from Cloudscape components
  // https://cloudscape.aws.dev/versions/from-v21-to-v30/known-migration-issues/#client-side-errors-due-to-resizeobserver
  if (
    event.message ===
    'ResizeObserver loop completed with undelivered notifications.'
  ) {
    event.stopImmediatePropagation();
    event.preventDefault();
    return;
  }

  logger.debug('GlobalError', event);

  // Handle errors from browser plugins or addons differently.
  const isFromAppSource = (event.filename ?? '').startsWith('http');
  const publisher = metricsService.getPublisher(
    isFromAppSource ? 'GlobalError' : 'GlobalErrorUnknown'
  );
  publisher.publishCounterMonitor(IMPRESSIONS, 1);
  publisher.publishStringTruncate('Message', event.message ?? 'NA');
  publisher.publishStringTruncate('Path', window.location.pathname);
  publisher.publishStringTruncate('Filename', event.filename);
  publisher.publishStringTruncate('UserAgent', navigator.userAgent);
};

/**
 * Capture unhandled promise rejections.
 */
const unhandledRejectionHandler = (event: PromiseRejectionEvent) => {
  logger.debug('UnhandledPromiseRejection', event.reason);
  const message =
    (typeof event.reason === 'string' && event.reason) || event.reason?.message;
  const publisher = metricsService.getPublisher('UnhandledPromiseRejection');
  publisher.publishCounterMonitor(IMPRESSIONS, 1);
  publisher.publishStringTruncate('Message', message ?? 'NA');
  publisher.publishStringTruncate('PathName', window.location.pathname);
  publisher.publishStringTruncate('UserAgent', navigator.userAgent);
};

const metricsService = {
  init(config: AppConfig): void {
    cachedConfig = config;
  },

  // Note: Some adblockers may block metrics being posted from localhost. See: https://sage.amazon.com/posts/746798
  getPublisher(method: string): KatalMetrics.Publisher {
    if (!cachedPublisher) cachedPublisher = createPublisher();
    return cachedPublisher.newChildActionPublisherForMethod(method);
  },

  createTimerStopWatch(
    name: string,
    startTime?: number
  ): KatalMetrics.Metric.TimerStopwatch {
    return new KatalMetrics.Metric.TimerStopwatch(name, startTime);
  },

  setupUncaughtHandlers() {
    window.addEventListener('error', errorHandler);
    window.addEventListener('unhandledrejection', unhandledRejectionHandler);
  },
};

export default metricsService;
