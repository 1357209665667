import { useEffect, useRef, useState } from 'react';
import {
  Button,
  Form,
  SpaceBetween,
  TextContent,
} from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';

import { PostAuthComponentParams } from '../../types/auth';
import metricsService from '../../services/metrics';
import CardContainer from '../CardContainer';
import { IMPRESSIONS } from '../../constants/metrics';
import { isEulaAccepted, acceptEula } from '../../services/eula';
import eulaMessages from '../../i18n/eula.messages';

const EulaCheck = ({
  user,
  onContinue,
  onCancel,
  config,
  consentAccepted,
}: PostAuthComponentParams) => {
  const { formatMessage } = useIntl();
  const [showEula, setShowEula] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasFormError, setHasFormError] = useState<boolean>(false);
  const metricsPublisher = useRef(metricsService.getPublisher('EULACheck'));
  useEffect(() => {
    if (!showEula) return;
    metricsPublisher.current.publishCounterMonitor(IMPRESSIONS, 1);
  }, [showEula]);
  useEffect(() => {
    if (config.enableEulaPostAuthFlow !== 'true') {
      setShowEula(false);
      onContinue();
    }
    if (isEulaAccepted(user, consentAccepted)) {
      onContinue();
    } else {
      setShowEula(true);
    }
  }, [onContinue, user, config.enableEulaPostAuthFlow, consentAccepted]);

  const handleAccept = async () => {
    setHasFormError(false);
    setIsLoading(true);
    try {
      await acceptEula(config.gandalfProfileEndpoint, user);
      metricsPublisher.current.publishCounterMonitor('Accept', 1);
      onContinue();
    } catch (error) {
      metricsPublisher.current.publishCounterMonitor('Error', 1);
      setHasFormError(true);
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    metricsPublisher.current.publishCounterMonitor('Cancel', 1);
    onCancel();
  };

  if (!showEula) return null;

  return (
    <CardContainer header={formatMessage(eulaMessages.header)}>
      <Form
        errorText={hasFormError && formatMessage(eulaMessages.eulaUpdateError)}
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              variant="link"
              onClick={() => handleCancel()}
              disabled={isLoading}
              data-testid="eula-cancel"
            >
              {formatMessage(eulaMessages.cancelButton)}
            </Button>
            <Button
              variant="primary"
              onClick={handleAccept}
              loading={isLoading}
              data-testid="eula-accept"
            >
              {formatMessage(eulaMessages.acceptButton)}
            </Button>
          </SpaceBetween>
        }
      >
        <TextContent>
          <p data-testid="eula-content">
            {formatMessage(eulaMessages.eulaInformation, {
              termsLink: (chunks) => (
                <a
                  href="https://aws.amazon.com/legal/learner-terms-conditions/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {chunks}
                </a>
              ),
              privacyLink: (chunks) => (
                <a
                  href="https://aws.amazon.com/privacy/?src=aws_training"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {chunks}
                </a>
              ),
            })}
          </p>
        </TextContent>
      </Form>
    </CardContainer>
  );
};

export default EulaCheck;
