import React from 'react';
import { IdPs, requiredLogoutUrlParams } from '../constants/providers';
import { AppConfig } from '../types/app';
import { useIntl } from 'react-intl';
import { TextContent } from '@amzn/awsui-components-react';
import logoutMessages from '../i18n/logout.messages';
import providersService from '../services/providers';

const LogoutInstructions = ({
  config,
  idp,
}: {
  config: AppConfig;
  idp: IdPs;
}): JSX.Element => {
  const { formatMessage } = useIntl();

  const clientID = providersService.getClientId(requiredLogoutUrlParams);
  const lxpName =
    config.clients[clientID].displayName === ''
      ? formatMessage(logoutMessages.fallbackLxpName)
      : config.clients[clientID].displayName;

  const enableHomePageNavigationInLogOutScreen =
    config.clients[clientID]?.enableHomePageNavigationInLogOutScreen;

  const homePageUrl = config.clients[clientID]?.homePageURL;

  const genericEnterpriseInstructions = (
    <TextContent>
      {enableHomePageNavigationInLogOutScreen && homePageUrl
        ? formatMessage(
            logoutMessages.organizationESSOSignOutInstructionsSkillBuilderUpdated,
            {
              lxp: lxpName,
              skillbuilderurl: (str) => <a href={homePageUrl}>{str}</a>,
            }
          )
        : formatMessage(
            logoutMessages.organizationESSOSignOutInstructionsUpdated,
            {
              lxp: lxpName,
            }
          )}
    </TextContent>
  );

  const idpLogoutInfo: { [i in IdPs]: React.ReactNode } = {
    [IdPs.OTP]: (
      <TextContent>
        {enableHomePageNavigationInLogOutScreen && homePageUrl
          ? formatMessage(
              logoutMessages.emailOtpSignOutInstructionsSkillBuilderUpdated,
              {
                lxp: lxpName,
                skillbuilderurl: (str) => <a href={homePageUrl}>{str}</a>,
              }
            )
          : formatMessage(logoutMessages.emailOtpSignOutInstructionsUpdated, {
              lxp: lxpName,
            })}
      </TextContent>
    ),
    [IdPs.AWSBuilderID]: (
      <>
        <TextContent>
          {enableHomePageNavigationInLogOutScreen && homePageUrl
            ? formatMessage(
                logoutMessages.bidSignOutInstructionsSkillBuilderUpdated,
                {
                  link: (str) => (
                    <a
                      href="https://profile.aws.amazon.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {str}
                    </a>
                  ),
                  instructions: (str) => (
                    <a
                      href="https://docs.aws.amazon.com/signin/latest/userguide/how-to-sign-out-in.html#sign-out-all-aws_builder_id"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {str}
                    </a>
                  ),
                  br: <br />,
                  lxp: lxpName,
                  skillbuilderurl: (str) => <a href={homePageUrl}>{str}</a>,
                }
              )
            : formatMessage(logoutMessages.bidSignOutInstructionsUpdated, {
                link: (str) => (
                  <a
                    href="https://profile.aws.amazon.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {str}
                  </a>
                ),
                instructions: (str) => (
                  <a
                    href="https://docs.aws.amazon.com/signin/latest/userguide/how-to-sign-out-in.html#sign-out-all-aws_builder_id"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {str}
                  </a>
                ),
                br: <br />,
                lxp: lxpName,
              })}
        </TextContent>
      </>
    ),
    [IdPs.LoginWithAmazon]: (
      <>
        <ul>
          <li>
            {' '}
            <TextContent>
              {formatMessage(logoutMessages.lwaSignOutInstructionsOne)}
            </TextContent>
          </li>
          <li>
            {' '}
            <TextContent>
              {formatMessage(logoutMessages.lwaSignOutInstructionsTwo)}
            </TextContent>
          </li>
          <li>
            {' '}
            <TextContent>
              {formatMessage(logoutMessages.lwaSignOutInstructionsThree)}
            </TextContent>
          </li>
        </ul>
        <TextContent>
          {formatMessage(logoutMessages.forMoreInformation, {
            link: (str) => (
              <a
                href="https://www.amazon.com/gp/help/customer/display.html?nodeId=GQL359FK7DM8294C"
                target="_blank"
                rel="noreferrer"
              >
                {str}
              </a>
            ),
          })}
        </TextContent>
      </>
    ),
    [IdPs.AWSSignIn]: <></>,
    [IdPs.AmazonFederate]: (
      <>
        <TextContent>
          {enableHomePageNavigationInLogOutScreen && homePageUrl
            ? formatMessage(
                logoutMessages.amazonFederateSignOutInstructionsSkillBuilderUpdated,
                {
                  lxp: lxpName,
                  skillbuilderurl: (str) => <a href={homePageUrl}>{str}</a>,
                  link: (str) => (
                    <a
                      href="https://w.amazon.com/bin/view/NextGenMidway/FAQ#How_do_I_logout_of_NextGen_Midway_so_I_can_test_login_.2F_reset_my_timeout_to_20_hours_before_starting_work_I_don.27t_want_interrupted.3F"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {str}
                    </a>
                  ),
                }
              )
            : formatMessage(
                logoutMessages.amazonFederateSignOutInstructionsUpdated,
                {
                  lxp: lxpName,
                  link: (str) => (
                    <a
                      href="https://w.amazon.com/bin/view/NextGenMidway/FAQ#How_do_I_logout_of_NextGen_Midway_so_I_can_test_login_.2F_reset_my_timeout_to_20_hours_before_starting_work_I_don.27t_want_interrupted.3F"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {str}
                    </a>
                  ),
                }
              )}
        </TextContent>
      </>
    ),
    [IdPs.AmazonSystemFederate]: <></>,
    [IdPs.ADFS]: {
      displayName: 'ADFS',
      instructions: genericEnterpriseInstructions,
    },
    [IdPs.JPMC]: {
      displayName: 'JPMC',
      instructions: genericEnterpriseInstructions,
    },
    [IdPs.ScaleTestIdp]: { displayName: 'Scale Test IDP', instructions: <></> },
    [IdPs.AWSPartnerNetwork]: (
      <>
        <TextContent>
          {enableHomePageNavigationInLogOutScreen && homePageUrl
            ? formatMessage(
                logoutMessages.awsPartnerNetworkSignOutInstructionsSkillBuilderUpdated,
                {
                  lxp: lxpName,
                  skillbuilderurl: (str) => <a href={homePageUrl}>{str}</a>,
                  link: (str) => (
                    <a
                      href="https://partnercentral.awspartner.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {str}
                    </a>
                  ),
                }
              )
            : formatMessage(
                logoutMessages.awsPartnerNetworkSignOutInstructionsUpdated,
                {
                  lxp: lxpName,
                  link: (str) => (
                    <a
                      href="https://partnercentral.awspartner.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {str}
                    </a>
                  ),
                }
              )}
        </TextContent>
      </>
    ),
    [IdPs.Accenture]: genericEnterpriseInstructions,
    [IdPs.AccentureUAT]: genericEnterpriseInstructions,
    [IdPs.testProvider]: <></>,
    [IdPs.BestBuy]: genericEnterpriseInstructions,
    [IdPs.Westpac]: genericEnterpriseInstructions,
    [IdPs.Sophos]: genericEnterpriseInstructions,
    [IdPs.Shell]: genericEnterpriseInstructions,
    [IdPs.Jefferies]: genericEnterpriseInstructions,
    [IdPs.Curtin]: genericEnterpriseInstructions,
    [IdPs.Principal]: genericEnterpriseInstructions,
    [IdPs.GandalfSession]: <></>,
  };

  return (
    <>
      {idpLogoutInfo[idp] ? idpLogoutInfo[idp] : genericEnterpriseInstructions}
    </>
  );
};

export default LogoutInstructions;
