import { defineMessages } from 'react-intl';

const identityConsentMessages = defineMessages({
  header: {
    id: 'IdentityConsent.header',
    defaultMessage: 'Join {orgName} ',
    description: 'Header of the identity consent section',
  },
  termsAndConditionsAndOrganizationDataAccessNoticeHeader: {
    id: 'IdentityConsent.termsAndConditionsAndOrganizationDataAccessNoticeHeader',
    defaultMessage: 'Terms and Conditions and Organization Data Access Notice',
    description: 'Notice of terms and conditions and organization data access',
  },
  termsAndConditionsAndOrganizationDataAccessNoticeOtpInBody: {
    id: 'IdentityConsent.termsAndConditionsAndOrganizationDataAccessNoticeOtpInBody',
    defaultMessage: `You agree to be bound by the <tandcLink>AWS Learner Terms and Conditions</tandcLink>, and the information you provide in connection with AWS Training will handled in accordance with the <privacyLink>AWS Privacy Notice</privacyLink> {br} Your origanization "<b>{orgName}</b>" will have access to your profile information, preferences, and training data. You can leave the organization and revoke this data access at any time from account profile`,
    description:
      'Body of the terms and conditions and organization data access notice',
  },
  termsAndConditionsAndOrganizationDataAccessNoticeOtpOutBody: {
    id: 'IdentityConsent.termsAndConditionsAndOrganizationDataAccessNoticeOtpOutBody',
    defaultMessage: `You agree to be bound by the <tandcLink>AWS Learner Terms and Conditions</tandcLink>, and the information you provide in connection with AWS Training will handled in accordance with the <privacyLink>AWS Privacy Notice</privacyLink> {br} Your origanization "<b>{orgName}</b>" will <b>NOT</b> have access to your profile information, preferences, and training data. You can leave the organization and revoke this data access at any time from account profile`,
    description:
      'Body of the terms and conditions and organization data access notice',
  },
  termsAndConditionsAndOrganizationDataAccessNoticeBody: {
    id: 'IdentityConsent.termsAndConditionsAndOrganizationDataAccessNoticeBody',
    defaultMessage: `By joining <b>{orgName}</b> to take AWS Training, you agree to be bound by the <tandcLink>AWS Learner Terms and Conditions</tandcLink>, and the information you provide in connection with AWS Training will handled in accordance with the <privacyLink>AWS Privacy Notice</privacyLink>. <b>{orgName}</b> will have access to your profile information, preferences, and training data. You can leave this organization and revoke data access at any time from your account profile.`,
    description:
      'Body of the terms and conditions and organization data access notice',
  },
  organizationDataAccessNoticeHeader: {
    id: 'IdentityConsent.organizationDataAccessNoticeHeader',
    defaultMessage: 'Organization Data Access Notice',
    description: 'Notice of organization data access',
  },
  organizationDataAccessNoticeOptInBody: {
    id: 'IdentityConsent.organizationDataAccessNoticeOptInBody',
    defaultMessage: `Your organization "<b>{orgName}</b>" will have access to your profile information, preferences, and training data pursuant to the <tandcLink>AWS Learner Terms and Conditions</tandcLink> and the <privacyLink>AWS Privacy Notice</privacyLink>. You can leave the organization and revoke this data access at any time from account profile`,
    description: 'Body of the organization data access notice',
  },
  organizationDataAccessNoticeBody: {
    id: 'IdentityConsent.organizationDataAccessNoticeBody',
    defaultMessage: `By joining <b>{orgName}</b> to take AWS Training, you agree <b>{orgName}</b> will have access to your profile information, preferences, and training data pursuant to the <tandcLink>AWS Learner Terms and Conditions</tandcLink>, and <privacyLink>AWS Privacy Notice</privacyLink>. You can leave the organization and revoke this data access at any time from your account profile.`,
    description: 'Body of the organization data access notice',
  },
  organizationDataAccessNoticeOptOutBody: {
    id: 'IdentityConsent.organizationDataAccessNoticeOptOutBody',
    defaultMessage: `Your organization "<b>{orgName}</b>" will <b>NOT</b> have access to your profile information, preferences, and training data pursuant to the <tandcLink>AWS Learner Terms and Conditions</tandcLink> and the <privacyLink>AWS Privacy Notice</privacyLink>. You can leave the organization and revoke this data access at any time from account profile`,
    description: 'Body of the organization data access notice',
  },
  areYouSure: {
    id: 'IdentityConsent.areYouSure',
    defaultMessage: 'Are you sure?',
    description: 'Are you sure?',
  },
  areYouSureBodyText1: {
    id: 'IdentityConsent.areYouSureBodyText1',
    defaultMessage: `If you want to joint your organization's AWS training in the future, contact your learning admin to get a new invitation.`,
    description: 'Body text 1 of are you sure?',
  },
  areYouSureBodyText2: {
    id: 'IdentityConsent.areYouSureBodyText2',
    defaultMessage: `To take training on your own, you can continue with your personal AWS Builder ID.`,
    description: 'Body text 2 of are you sure?',
  },
  continueToIndividualAccount: {
    id: 'IdentityConsent.continueToIndividualAccount',
    defaultMessage: 'Continue to individual account',
    description: 'Continue to individual account',
  },
  cancelButton: {
    id: 'IdentityConsent.cancelButton',
    defaultMessage: 'Cancel',
    description: 'Cancel button',
  },
  signOutButton: {
    id: 'IdentityConsent.signOutButton',
    defaultMessage: 'Continue to sign out',
    description: 'Sign Out button',
  },
  acceptButton: {
    id: 'IdentityConsent.acceptButton',
    defaultMessage: 'Accept',
    description: 'Accept button',
  },
  doNotJoinOrganization: {
    id: 'IdentityConsent.doNotJoinOrganization',
    defaultMessage: 'Do not join organization',
    description: 'Do not join organization',
  },
  nonSsoDenyConsentText: {
    id: 'IdentityConsent.nonssoDenyConsentText',
    defaultMessage:
      'If you decline to join, you will not be able to access the training provided by this organization, and your account will remain in an individual status. To join later, contact your learning administrator for a new invitation.',
    description: 'No, I do not consent',
  },
  ssoDenyConsentText: {
    id: 'IdentityConsent.ssoDenyConsentText',
    defaultMessage:
      'If you decline to join, you will not be able to access the training provided by this organization. To join later, sign in again by using your single sign-on credentials.',
    description: 'No, I do not consent',
  },
  recommendBuilderIdText: {
    id: 'IdentityConsent.recommendBuilderIdText',
    defaultMessage:
      'To take training by using an individual account, sign in or sign up with your personal AWS Builder ID.',
    description: 'Recommend Builder ID',
  },
});

export default identityConsentMessages;
