import { useRef, useEffect, useState, useCallback } from 'react';
import { TextContent, SpaceBetween, Box } from '@amzn/awsui-components-react';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import { useIntl } from 'react-intl';

import providersService from '../services/providers';
import metricsService from '../services/metrics';
import SignInContainerLayout from '../components/SignInContainerLayout';
import ProviderButton from '../components/ProviderButton';
import HelpLink from '../components/HelpLink';
import CardContainer from '../components/CardContainer';
import usePageTitle from '../hooks/usePageTitle';
import TopHeader from '../components/TopHeader';
import Main from '../components/Main';
import Footer from '../components/Footer';
import useSignIn, { UIState } from '../hooks/useSignIn';
import { IdProvider } from '../types/auth';
import { startAuthFlow } from '../services/auth';
import { IMPRESSIONS } from '../constants/metrics';
import { AppConfig } from '../types/app';
import { IdPs } from '../constants/providers';
import signinMessages from '../i18n/signin.messages';
import { useStateStorage } from '../contexts/StateStorageContextProvider';
import { useNonceGenerator } from '../contexts/NonceGeneratorContextProvider';
import LinkButton from '../components/LinkButton';
import useMaintenanceInfo from '../hooks/useMaintenanceInfo';
import KatalMetricsPublisher from '@amzn/katal-metrics/lib/KatalMetricsPublisher';
import { rumService } from '../services/rum';
import { useAmplifyConfig } from '../hooks/useAmplifyConfig';
import styles from './SignIn.module.css';
import React from 'react';
import EnterpriseEmailLookup from './EnterpriseEmailLookup';
import Cookies from 'js-cookie';
import { VERIFY_EMAIL_COOKIE_NAME } from '../components/postAuth/VerifyEmail';

function SignIn({
  config,
  providers,
}: {
  config: AppConfig;
  providers: IdProvider[];
}) {
  usePageTitle();
  const { formatMessage } = useIntl();
  const [showAllOptions, setShowAllOptions] = useState(false);
  const metricsPublisher = useRef(metricsService.getPublisher('SignInPage'));
  const stateStorage = useStateStorage();
  const nonceGenerator = useNonceGenerator();
  const maintenanceInfo = useMaintenanceInfo(config, providers);
  const providerRef = useRef<HTMLDivElement | null>(null);
  const [switchedFromLast, setSwitchedFromLast] = useState<boolean>(false);

  useEffect(() => {
    metricsPublisher.current.publishCounterMonitor(IMPRESSIONS, 1);
    Cookies.remove(VERIFY_EMAIL_COOKIE_NAME);
    window.addEventListener('pageshow', function (event) {
      const isBFEventOld =
        window.performance &&
        window.performance.navigation.type ===
          window.performance.navigation.TYPE_BACK_FORWARD;
      const isBFEventNew =
        performance.getEntriesByType('navigation')[0].type === 'back_forward';
      if (isBFEventOld || isBFEventNew || event.persisted) {
        this.window.location.reload();
      }
    });
  }, []);
  const handleRedirectToIdp = useCallback(
    (provider: IdProvider) => {
      startAuthFlow({
        provider,
        config,
        storage: stateStorage,
        nonceGenerator,
      });
    },
    [config, stateStorage, nonceGenerator]
  );
  useAmplifyConfig(config);
  const { uiState, context } = useSignIn(
    config,
    providers,
    metricsPublisher.current,
    handleRedirectToIdp
  );

  useEffect(() => {
    if (uiState === UIState.showLastUsedProvider) {
      metricsPublisher.current.publishCounterMonitor(
        'LastUsedProviderImpression',
        1
      );
    }
  }, [uiState]);

  useEffect(() => {
    if (providerRef.current) {
      providerRef.current.focus();
      setSwitchedFromLast(false);
    }
  }, [switchedFromLast, providerRef]);

  // Do not show any UI while loading.
  if (uiState === UIState.loading) return null;

  const enableEmailLookup = Boolean(config?.enableEmailLookup === 'true');
  const enableCookieComponent = Boolean(
    config?.enableCookieComponent === 'true'
  );

  const clientID = providersService.getClientId();
  const idPsToHide = config.clients[clientID]
    ? config.clients[clientID].idPsToHide
    : [];

  const shouldShowLastUsedProvider =
    !showAllOptions &&
    context.suggestedProvider !== undefined &&
    !idPsToHide?.includes(context.suggestedProvider.idp) &&
    uiState === UIState.showLastUsedProvider;

  const bidProvider = !idPsToHide?.includes(IdPs.AWSBuilderID)
    ? providers.find((p) => p.idp === IdPs.AWSBuilderID)
    : undefined;
  const lwaProvider = !idPsToHide?.includes(IdPs.LoginWithAmazon)
    ? providers.find((p) => p.idp === IdPs.LoginWithAmazon)
    : undefined;
  const apnProvider = !idPsToHide?.includes(IdPs.AWSPartnerNetwork)
    ? providers.find((p) => p.idp === IdPs.AWSPartnerNetwork)
    : undefined;
  const amazonFederateProvider = providers.find(
    (p) => p.idp === IdPs.AmazonFederate
  );
  const visibleProviders = providers
    .filter(providersService.isVisibleProvider)
    .filter((p) => !idPsToHide?.includes(p.idp))
    .filter((p) => p.idp !== IdPs.AWSBuilderID) // AWS Builder ID is handled separately.
    .filter((p) => p.idp !== IdPs.LoginWithAmazon) // LWA is handled separately.
    .filter((p) => p.idp !== IdPs.AWSPartnerNetwork) // APN is handled separately.
    .filter((p) => p.idp !== IdPs.AmazonFederate); // AmazonFederate is hsandled separately.

  const providerRequireSeperator = providers
    .filter((p) => p.idp !== IdPs.AWSBuilderID)
    .filter(providersService.isVisibleProvider);

  const shouldShowEmailLookup =
    enableEmailLookup && providersService.shouldShowEnterpriseChoice(providers);
  const shouldShowBidSeparator = Boolean(
    bidProvider && providerRequireSeperator.length > 0
  );
  const shouldShowLwaSeparator = Boolean(
    !shouldShowBidSeparator && lwaProvider && providers.length > 1 && false
  );

  let maintenanceBanner = <></>;
  if (maintenanceInfo.displayMaintenance) {
    maintenanceBanner = (
      <Alert dismissible={false} header={maintenanceInfo.header} type="info">
        {maintenanceInfo.content}
      </Alert>
    );
  }
  const publishLastUsedProviderMetric = (
    provider: IdProvider,
    metricsPublisher: React.MutableRefObject<KatalMetricsPublisher>
  ) => {
    const urlParams = new URLSearchParams(window.location.search);
    const clientId = urlParams.get('client_id') || 'NA';
    rumService.recordEvent('LastUsedIdPSignIn', {
      IdP: provider,
      ClientId: clientId,
    });
    metricsPublisher.current.publishCounterMonitor('LastUsedIdPSignIn', 1);
    metricsPublisher.current.publishCounterMonitor(
      `LastUsedIdPSignIn:${provider.idp}`,
      1
    );
  };

  return (
    <>
      <TopHeader config={config} />
      <Main config={config}>
        <SignInContainerLayout>
          <br />
          {maintenanceBanner}

          <div aria-live="assertive">
            {Boolean(uiState === UIState.preferredIdpNotFound) && (
              <Alert type="warning">
                {formatMessage(signinMessages.preferredIdpNotFound)}
              </Alert>
            )}
            {Boolean(uiState === UIState.preferredIdpError) && (
              <Alert type="warning">
                {formatMessage(signinMessages.preferredIdpError)}
              </Alert>
            )}
          </div>

          <div className={styles.heading_div}>
            <TextContent>
              <h1>{formatMessage(signinMessages.header)}</h1>
            </TextContent>
          </div>

          <CardContainer>
            <SpaceBetween direction="vertical" size="l">
              <div ref={providerRef}>
                {shouldShowLastUsedProvider ? (
                  // Show Last Used Provider
                  <>
                    {bidProvider &&
                    context.suggestedProvider !== undefined &&
                    context.suggestedProvider.idp === 'LoginWithAmazon' ? (
                      <>
                        <ProviderButton
                          provider={bidProvider!}
                          key={bidProvider?.idp}
                          onClick={handleRedirectToIdp}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                    {context.suggestedProvider !== undefined &&
                    context.suggestedProvider.idp === 'LoginWithAmazon' ? (
                      <></>
                    ) : (
                      <ProviderButton
                        provider={context.suggestedProvider!}
                        onClick={() => {
                          publishLastUsedProviderMetric(
                            context.suggestedProvider!,
                            metricsPublisher
                          );
                          handleRedirectToIdp(context.suggestedProvider!);
                        }}
                        lastUsedIdp={true}
                      />
                    )}
                  </>
                ) : (
                  // Show full UI.
                  // Depending on the settings.json configuration, full UI consists of:
                  //  - AWS Builder ID
                  //  - Visual divider
                  //  - LWA
                  //  - APN
                  //  - Organization SSO
                  //  - List of other all other visible providers
                  //  - AmazonFederate
                  //  - Fallback in case email lookup is disabled
                  <>
                    {/* AWS Builder ID provider. */}
                    {bidProvider ? (
                      <ProviderButton
                        provider={bidProvider}
                        key={bidProvider.idp}
                        onClick={handleRedirectToIdp}
                      />
                    ) : null}

                    {/* Visual signin mechanism separator. */}
                    {shouldShowBidSeparator ? (
                      <h3
                        style={{
                          textAlign: 'center',
                        }}
                        data-testid={'seperator'}
                      >
                        {formatMessage(signinMessages.signInMechanismSeparator)}
                      </h3>
                    ) : null}

                    {/* Visual signin mechanism separator. */}
                    {shouldShowLwaSeparator ? (
                      <p
                        style={{
                          textAlign: 'center',
                        }}
                      >
                        {formatMessage(
                          signinMessages.signInMechanismSeparatoLowerCase
                        )}
                      </p>
                    ) : null}

                    {/* List all potentially visible providers. */}
                    {visibleProviders.map((provider) => (
                      <ProviderButton
                        provider={provider}
                        key={provider.idp}
                        onClick={handleRedirectToIdp}
                      />
                    ))}

                    {/* Email lookup button. */}
                    {shouldShowEmailLookup ? (
                      <EnterpriseEmailLookup
                        config={config}
                        providers={providers}
                      />
                    ) : null}

                    {/* APN provider. */}
                    {apnProvider ? (
                      <ProviderButton
                        provider={apnProvider}
                        key={apnProvider.idp}
                        onClick={handleRedirectToIdp}
                      />
                    ) : null}

                    {/* Amazon employee sign-in provider. */}
                    {amazonFederateProvider ? (
                      <ProviderButton
                        provider={amazonFederateProvider}
                        key={amazonFederateProvider.idp}
                        onClick={handleRedirectToIdp}
                        lastUsedIdp={false}
                      />
                    ) : null}

                    {/* NOTE: Temporary logic for when email lookup is toggleable. */}
                    {!enableEmailLookup &&
                    providersService.shouldShowEnterpriseChoice(providers) ? (
                      <TextContent>
                        <p>
                          {formatMessage(signinMessages.organizationSigninInfo)}
                        </p>
                      </TextContent>
                    ) : null}
                  </>
                )}
              </div>
            </SpaceBetween>
          </CardContainer>
          {shouldShowLastUsedProvider ? (
            <Box margin={{ top: 's' }} float="left">
              <LinkButton
                onClick={() => {
                  setShowAllOptions(true);
                  setSwitchedFromLast(true);
                  metricsPublisher.current.publishCounterMonitor(
                    'ShowAllOptions',
                    1
                  );
                }}
                data-testid="show-all-options"
              >
                {formatMessage(signinMessages.changeSigninOption)}
              </LinkButton>
            </Box>
          ) : (
            <></>
          )}
          <HelpLink />
        </SignInContainerLayout>
      </Main>
      <Footer enableCookieComponent={enableCookieComponent} />
    </>
  );
}

export default SignIn;
