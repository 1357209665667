import { useEffect, useRef, useState } from 'react';
import {
  Button,
  Form,
  Modal,
  SpaceBetween,
  TextContent,
  Box,
  Header,
  Container,
} from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';

import { PostAuthComponentParams } from '../../types/auth';
import metricsService from '../../services/metrics';
import { IMPRESSIONS } from '../../constants/metrics';
import { isStandardProvider } from '../../constants/providers';
import learnerConsentOnMerge3pMessages from '../../i18n/learnerConsentOnMerge3p.messages';
import logger from '../../utils/logger';
import { handleMerge } from '../../services/mergeConsent3p';
import useVibeAttributesForMerge from '../../hooks/useVibeAttributesForMerge';
import Main from '../Main';
import ContainerLayout from '../ContainerLayout';

const LearnerConsentOnMerge3p = ({
  user,
  onContinue,
  onCancel,
  config,
}: PostAuthComponentParams) => {
  const { formatMessage } = useIntl();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasFormError, setHasFormError] = useState<boolean>(false);
  const [showConsentFlow, setShowConsentFlow] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isESSO, setIsESSO] = useState<boolean>(false);
  const metricsPublisher = useRef(
    metricsService.getPublisher('LearnerConsentOnMerge3p')
  );

  const { userMergeConsentRequired, error } = useVibeAttributesForMerge(
    config,
    user
  );

  if (error !== undefined) {
    logger.error(`Error fetching attributes: ${error}`);
    throw new Error('Failed getting Vibe Attributes');
  }

  useEffect(() => {
    if (
      config.enableLearnerConsentOnMerge3pPostAuthFlow !== 'true' ||
      userMergeConsentRequired === 'false'
    ) {
      onContinue();
    }
    metricsPublisher.current.publishCounterMonitor(IMPRESSIONS, 1);
  }, [config, userMergeConsentRequired, onContinue]);

  useEffect(() => {
    if (
      userMergeConsentRequired !== 'false' &&
      userMergeConsentRequired !== undefined
    ) {
      setShowConsentFlow(true);
    }
  }, [userMergeConsentRequired]);

  const handleAccept = async () => {
    if (userMergeConsentRequired) {
      setHasFormError(false);
      setIsLoading(true);
      try {
        await handleMerge(
          config.gandalfProfileEndpoint,
          user,
          userMergeConsentRequired
        );
        metricsPublisher.current.publishCounterMonitor('Accept', 1);
        onContinue();
      } catch (error) {
        handleError();
      }
    } else {
      handleError();
    }
  };

  const handleDeclineConfirmation = async () => {
    setHasFormError(false);
    setIsLoading(true);
    try {
      await handleMerge(config.gandalfProfileEndpoint, user, 'false');
      metricsPublisher.current.publishCounterMonitor('Decline', 1);
      onContinue();
    } catch (error) {
      handleError();
    }
  };

  const showDeclineModal = async () => {
    if (isStandardProvider(user.providerName)) {
      setIsESSO(false);
    } else {
      setIsESSO(true);
    }
    setIsVisible(true);
  };

  const handleError = async () => {
    metricsPublisher.current.publishCounterMonitor('Error', 1);
    setHasFormError(true);
    setIsLoading(false);
    setIsVisible(false);
  };

  if (!showConsentFlow) return <></>;

  return (
    <>
      <Main config={config}>
        <ContainerLayout>
          <Header variant="h1" data-testid={'join-org-header'}>
            {formatMessage(learnerConsentOnMerge3pMessages.header, {
              orgName: userMergeConsentRequired,
              b: (str) => <b>{str}</b>,
            })}
          </Header>
          <br />
          <Container
            header={
              <Header data-testid={'data-notice-header'}>
                {formatMessage(
                  learnerConsentOnMerge3pMessages.organizationDataAccessNoticeHeader
                )}
              </Header>
            }
          >
            <Form
              errorText={
                hasFormError &&
                formatMessage(learnerConsentOnMerge3pMessages.verificationError)
              }
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    variant="link"
                    onClick={showDeclineModal}
                    loading={isLoading}
                    data-testid="learnerConsentOnMerge3pMessages-decline"
                  >
                    {formatMessage(
                      learnerConsentOnMerge3pMessages.doNotJoinOrganization
                    )}
                  </Button>
                  <Button
                    variant="primary"
                    onClick={handleAccept}
                    loading={isLoading}
                    data-testid="learnerConsentOnMerge3pMessages-accept"
                  >
                    {formatMessage(
                      learnerConsentOnMerge3pMessages.acceptButton
                    )}
                  </Button>
                </SpaceBetween>
              }
            >
              <TextContent>
                <p data-testid="learnerConsentOnMerge3pMessages-content">
                  {formatMessage(
                    learnerConsentOnMerge3pMessages.learnerConsentOnMerge3pDescription,
                    {
                      orgName: userMergeConsentRequired,
                      b: (str) => <b>{str}</b>,
                      termsLink: (chunks) => (
                        <a
                          href="https://aws.amazon.com/legal/learner-terms-conditions/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {chunks}
                        </a>
                      ),
                      privacyLink: (chunks) => (
                        <a
                          href="https://aws.amazon.com/privacy/?src=aws_training"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {chunks}
                        </a>
                      ),
                    }
                  )}
                </p>
              </TextContent>
            </Form>
            <Modal
              onDismiss={() => setIsVisible(false)}
              visible={isVisible}
              footer={
                isESSO ? (
                  <Box float="right">
                    <Button
                      variant="primary"
                      onClick={() => setIsVisible(false)}
                      disabled={isLoading}
                      data-testid="learnerConsentOnMerge3pMessages-ok"
                    >
                      {formatMessage(
                        learnerConsentOnMerge3pMessages.acceptButton
                      )}
                    </Button>
                  </Box>
                ) : (
                  <Box float="right">
                    <Button
                      variant="link"
                      onClick={() => setIsVisible(false)}
                      disabled={isLoading}
                      data-testid="learnerConsentOnMerge3pMessages-cancel"
                    >
                      {formatMessage(
                        learnerConsentOnMerge3pMessages.cancelButton
                      )}
                    </Button>
                    <Button
                      variant="primary"
                      onClick={handleDeclineConfirmation}
                      loading={isLoading}
                      data-testid="learnerConsentOnMerge3pMessages-confirm"
                    >
                      {formatMessage(
                        learnerConsentOnMerge3pMessages.continueToIndividualAccount
                      )}
                    </Button>
                  </Box>
                )
              }
              header={formatMessage(
                learnerConsentOnMerge3pMessages.confirmationModalHeader
              )}
            >
              {formatMessage(
                isESSO
                  ? learnerConsentOnMerge3pMessages.confirmationModalDescriptionEsso
                  : learnerConsentOnMerge3pMessages.confirmationModalDescriptionNonEsso
              )}
            </Modal>
          </Container>
        </ContainerLayout>
      </Main>
    </>
  );
};

export default LearnerConsentOnMerge3p;
