import { useState, createContext, useContext } from 'react';
import { IntlProvider } from 'react-intl';
import { DEFAULT_LOCALE, HC_LOCALES } from '../constants/locales';
import { LOCALE_SELECTION } from '../constants/storage';

import translations from '../i18n/translations';
import { localStorageAdapter } from '../services/storage';

export const LocaleContext = createContext({
  locale: '',
  setLocale: (_: string) => {},
});

export const useLocaleContext = () => useContext(LocaleContext);

const isLocaleSupported = (locale: string) => Boolean(HC_LOCALES[locale]);

/**
 * Tries to get the browsers configured language in case it is in the list of
 * supported locales.
 * Note: Given a browser language like 'en-US' both 'en-US' and 'en' will be
 * checked.
 */
function getSupportedBrowserLocaleMaybe() {
  let browserLocale: string | undefined;

  // Early return is navigator.languages is not supported in the clients browser.
  if (!navigator.languages) return;

  navigator.languages.find((locale) => {
    if (isLocaleSupported(locale)) {
      browserLocale = locale;
    }
    const shortLocale = locale.split('-')[0];
    if (isLocaleSupported(shortLocale)) {
      browserLocale = shortLocale;
    }
    return Boolean(browserLocale);
  });

  return browserLocale;
}

export function getInitialLocaleSelection(defaultLocale: string): string {
  // Try to get the users locale selection from localStorage.
  const userSelection = localStorageAdapter.getItem(LOCALE_SELECTION);
  if (userSelection && isLocaleSupported(userSelection)) {
    return userSelection;
  }

  // Try to get locale selection from browser language API.
  const browserLocale = getSupportedBrowserLocaleMaybe();
  if (browserLocale) return browserLocale;

  return defaultLocale;
}

const LocaleContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [locale, setLocale] = useState(
    getInitialLocaleSelection(DEFAULT_LOCALE)
  );

  const handleSetLocale = (updatedLocale: string): void => {
    if (!isLocaleSupported(updatedLocale)) return;
    localStorageAdapter.setItem(LOCALE_SELECTION, updatedLocale);
    setLocale(updatedLocale);
  };

  return (
    <LocaleContext.Provider value={{ locale, setLocale: handleSetLocale }}>
      <IntlProvider
        defaultLocale={DEFAULT_LOCALE}
        locale={locale}
        messages={translations[locale]}
      >
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};

export default LocaleContextProvider;
