import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { useLocation, useNavigate } from 'react-router-dom';

import {
  Button,
  Container,
  Form,
  FormField,
  Header,
  Input,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import logger from '../utils/logger';
import {
  startSession,
  signUp,
  newUser,
  answerLocaleChallenge,
} from '../services/emailOTPAuth';
import usePageTitle from '../utils/usePageTitle';
import otpMessages from '../i18n/otp.messages';

import { getInitialLocaleSelection } from '../services/locale';
import TopHeader from '../components/TopHeader';
import ContainerLayout from '../components/ContainerLayout';
import Main from '../components/Main';
import Footer from '../components/Footer';
import { AppConfig } from '../types/app';
import styles from '../components/CardContainer.module.css';
import HelpLink from '../components/HelpLink';
import * as AuthService from '../services/emailOTPAuth';
import { AppURL } from '../constants/urls';

function EmailOTPSignIn({ config }: { config: AppConfig }) {
  if (config.enableOtp === 'false' || !config.enableOtp) {
    throw new Error('Invalid path');
  }
  const { formatMessage } = useIntl();
  usePageTitle('One-time email passcode');
  const location = useLocation();
  const navigate = useNavigate();
  AuthService.init(config);
  const hState = location.state;
  let headerText = formatMessage(otpMessages.header);
  let formText = formatMessage(otpMessages.description);
  let buttonLabel = formatMessage(otpMessages.buttonLabel);
  let emailError = '';
  let emailText = '';
  if (hState) {
    emailText = hState.userEmail;
    if (hState.sessionExpired) {
      headerText = formatMessage(otpMessages.headerSessionExpired);
      formText = formatMessage(otpMessages.descriptionSessionExpired);
      buttonLabel = formatMessage(otpMessages.buttonLabelSessionExpired);
    } else if (hState.otpError) {
      emailError = formatMessage(otpMessages.emailOTPError);
    }
  }
  const [emailValue, setEmailValue] = useState(emailText);
  const [emailEntered, setEmailEntered] = useState(emailText !== ''); // start true if redirected back with an email already entered
  const [emailFormatError, setEmailFormatError] = useState(emailError);
  const [loading, setLoading] = useState(false);

  const enableCookieComponent = Boolean(
    config?.enableCookieComponent === 'true'
  );

  const handleEmailChange = (e: any) => {
    if (isEmail(e.detail.value)) {
      setEmailEntered(true);
      setEmailFormatError('');
    } else {
      setEmailEntered(false);
    }
    setEmailValue(e.detail.value);
  };

  const isEmail = (input: string) => {
    const emailRegExp = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    return emailRegExp.test(input);
  };

  const handleSendOTPClick = async (e?: CustomEvent) => {
    e?.preventDefault();
    if (!isEmail(emailValue)) {
      setEmailFormatError(formatMessage(otpMessages.emailFormatError));
      return;
    }
    try {
      setLoading(true);
      const isNewUser = (await newUser(emailValue)) as boolean;
      if (isNewUser) {
        await signUp(emailValue);
      }
      const cognitoUser = await startSession(emailValue);
      await answerLocaleChallenge(getInitialLocaleSelection(), cognitoUser);
      setLoading(false);
      navigate(`${AppURL.OTPChallenge}${location.search}`, {
        state: {
          cognitoUserName: cognitoUser.getUsername(),
          cognitoSession: (cognitoUser as any).Session,
          userEmail: emailValue,
        },
      });
    } catch (err) {
      setEmailFormatError(formatMessage(otpMessages.emailOTPError));
      setLoading(false);
      logger.error(err);
    }
  };

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    await handleSendOTPClick();
  };

  useEffect(() => {
    setEmailFormatError(emailError);
  }, [emailError]);

  return (
    <>
      <TopHeader config={config} />
      <Main config={config}>
        <ContainerLayout>
          <Container
            header={
              <Header
                className={styles.header}
                variant="h2"
                headingTagOverride="h1"
                description={formText}
              >
                {headerText}
              </Header>
            }
          >
            <form onSubmit={handleSubmit}>
              <Form
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button
                      data-testid="back-button"
                      onClick={() => navigate(-1)}
                      formAction="none"
                    >
                      {formatMessage(otpMessages.previousButtonLabel)}
                    </Button>
                    <Button
                      variant="primary"
                      data-testid="submit-button"
                      disabled={!emailEntered}
                      formAction="submit"
                      loading={loading}
                    >
                      {buttonLabel}
                    </Button>
                  </SpaceBetween>
                }
              >
                <SpaceBetween direction="vertical" size="s">
                  <FormField
                    errorText={emailFormatError}
                    stretch={true}
                    label={formatMessage(otpMessages.emailInputName)}
                  >
                    <Input
                      name="email"
                      type="email"
                      data-testid="text-input"
                      value={emailValue}
                      onChange={(event) => {
                        handleEmailChange(event);
                      }}
                      disabled={loading}
                      disableBrowserAutocorrect={true}
                      // eslint-disable-next-line jsx-a11y/no-autofocus
                      autoFocus={true} // OK to use since this is the only purpose of the page.
                      className={styles.mobileInput}
                      autoComplete="email"
                    />
                  </FormField>
                </SpaceBetween>
              </Form>
            </form>
          </Container>
          <HelpLink />
        </ContainerLayout>
      </Main>
      <Footer enableCookieComponent={enableCookieComponent} />
    </>
  );
}

export default EmailOTPSignIn;
