import styles from './ContainerLayout.module.css';
import { PostAuthFlows } from '../pages/AuthResponse';

const ContainerLayout = ({
  children,
  currentPostAuthFlow,
}: {
  children: React.ReactNode;
  currentPostAuthFlow?: PostAuthFlows;
}) => {
  // TODO: Remove this allow list once all components have been updated
  // and verified as part of https://tasks.awstcprojects.com/browse/ID-1488
  const responsiveFlows = [
    PostAuthFlows.ProfileAndPreferences,
    PostAuthFlows.LearnerConsentOnOrgInvite,
    PostAuthFlows.SuspendedAccount,
    PostAuthFlows.ProfileDeactivated,
    PostAuthFlows.SignInSwitchNeeded,
  ];

  let classes = styles.container;
  if (currentPostAuthFlow && responsiveFlows.includes(currentPostAuthFlow)) {
    const isProfileAndPreferences =
      currentPostAuthFlow === PostAuthFlows.ProfileAndPreferences;
    classes = `${styles.responsiveContainer} ${
      isProfileAndPreferences ? styles.max : styles.default
    }`;
  }

  return <div className={classes}>{children}</div>;
};

export default ContainerLayout;
