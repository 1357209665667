import { GandalfUser } from '../types/auth';
import { getEmailDomain } from './emitAccountLinkingRUMEvent';
import { rumService } from './rum';
import { getInitialLocaleSelection } from '../contexts/LocaleContextProvider';
import { DEFAULT_LOCALE } from '../constants/locales';
import { PostAuthRumEvent } from '../constants/metrics';

export function emitPostAuthRumEvent(
  user: GandalfUser,
  event: PostAuthRumEvent
) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const clientidUsed = urlSearchParams.get('client_id');
  const redirectUriUsed = urlSearchParams.get('redirect_uri');

  rumService.recordEvent(event, {
    sourceIdP: user.providerName,
    clientId: clientidUsed,
    redirectUri: redirectUriUsed,
    newUser: user.new_user,
    emailDomain: getEmailDomain(user.email!),
    languageSelected: getInitialLocaleSelection(DEFAULT_LOCALE),
    vibeID: user.vibeId,
    hatID: user.hatId,
  });
}
